<template>
  <div class="container-fluid m-0 p-0">
    <div class="row m-0 p-3">
      <div class="col-12 m-0 p-0 shadow mb-5 bg-white rounded">
        <div class="row m-0 p-3">
          <div class="col-12 col-xl-6 mx-0 mb-3 mb-xl-0">
            <p class="font-1-2s font-600">History Login</p>
          </div>
        </div>

        <hr class="m-0 p-0" />
        <div class="col-12 col-xl-4 my-3">
          <div class="input-group input-group-sm   ">
            <div class="input-group-prepend input-group-sm">
              <select class="custom-select" v-model="mode">
                <option v-for="(e, i) in select_mode" :key="i" :value="e.key">
                  {{ e.name }}
                </option>
              </select>
            </div>
            <input
              type="search"
              class="form-control form-control-sm"
              placeholder="ค้นหา / Seach"
              v-model="find"
              v-on:keyup.enter="Search()"
            />
            <div class="input-group-append">
              <button class="btn btn-sm bt-main" @click="Search()">
                ค้นหา
              </button>
            </div>
          </div>
        </div>
        <div class="col-12  ">
          <p class="px-3 font-600 w-100" style="display: flex">
            สินค้า&nbsp;
            <span class="text-main">{{
              new Intl.NumberFormat().format(totalRows)
            }}</span>
            &nbsp;รายการ
            <span style="flex: 1; text-align: right; white-space: nowrap;"
              ><button class="btn btn-sm bt-bnb" @click="export_excel">
                นำออกข้อมูล <span v-html="$svg_icon.excel"></span></button
            ></span>
          </p>
        </div>
        <div class="col-12">
          <b-table
            :empty-text="'ไม่พบข้อมูล / No Data'"
            :fields="fields"
            :items="items"
            :current-page="page_num"
            :per-page="page_size"
            outlined
            hover
            show-empty
            responsive
            class="font-0-8s"
          >
            <template #cell(No)="row">
              {{ row.index + 1 + (page_num - 1) * page_size }}
            </template>
            <template #cell(active)="row">
              <span
                :class="[row.item.active == 'Y' ? 'badge-main' : 'badge-twd']"
                class="badge"
                >{{ row.item.active !== "Y" ? "No" : "Yes" }}</span
              >
            </template>
          </b-table>
        </div>
        <div class="col-12 mb-3">
          <div class="row justify-content-end">
            <div class="col-6 col-xl-1 input-group-prepend input-group-sm">
              <select class="custom-select" v-model="page_size">
                <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                  {{ e }}
                </option>
              </select>
            </div>
            <div class="col-6 col-xl-3">
              <b-pagination
                v-model="page_num"
                :total-rows="totalRows"
                :per-page="page_size"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HistoryLogin",
  data() {
    return {
      mode: "firstname",
      select_mode: [
        { name: "ค้นหาด้วย FirstName", key: "firstname" },
        { name: "ค้นหาด้วย LastName", key: "lastname" },
        { name: "ค้นหาด้วย PermissionName", key: "permission_name" },
        { name: "ค้นหาด้วย LastLogin", key: "lastlogin" },
      ],
      totalRows: 0,
      find: "",
      items: [],
      itemlist: [],
      page_size: 10,
      page_size_list: [10, 15, 20, 25, 30],
      page_num: 1,
      fields: [
        { key: "No", label: "No.", class: "text-center" },
        { key: "firstname", label: "FirstName" },
        { key: "lastname", label: "LastName" },
        {
          key: "permission_name",
          label: "PermissionName",
          class: "text-center",
        },
        {
          key: "ceratedate",
          label: "CerateDate",
        },
        { key: "active", label: "Active", class: "text-center" },
        { key: "lastlogin", label: "LastLogin" },
      ],
    };
  },
  watch: {
    find: function() {
      if (this.find == "") {
        this.unSearch();
      }
    },
  },

  mounted() {
    this.getdata();
  },
  methods: {
    unSearch() {
      this.items = this.itemlist;
      this.totalRows = this.items.length;
    },
    Search() {
      var results = [];
      if (this.find == "") {
        // this.$emit("getProductByRemark");
      } else {
        let find = this.find;
        if (this.mode !== "active") {
          find = this.find;
        } else {
          find =
            String(this.find).toUpperCase() == "YES"
              ? 1
              : String(this.find).toUpperCase() == "NO"
              ? 0
              : this.find;
        }
        var toSearch = String(find)
          .trim()
          .toLocaleUpperCase()
          .replace(/ /g, "");
        var objects = this.itemlist;
        for (var i = 0; i < objects.length; i++) {
          var index = String(objects[i][this.mode])
            .trim()
            .toLocaleUpperCase()
            .replace(/ /g, "");
          if (index.indexOf(toSearch) != -1) {
            results.push(objects[i]);
          }
        }
        this.items = results;
        this.totalRows = this.items.length;
      }
    },
    async export_excel() {
      let date = `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDay()}-${new Date().getTime()}`;

      let json = this.items;
      let name = `HistoryLogin(${date}).xlsx`;
      this.$serviceMain.JsonToExcat(json, name);
      try {
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async getdata() {
      try {
        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `adminit/getHistoryLogin`,
          {},
          1
        );
        this.items = getAPI.data;
        this.itemlist = getAPI.data;
        this.totalRows = getAPI.data.length;
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
  },
};
</script>

<style></style>
